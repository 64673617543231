import PermissionsClass from '@/security/Permission-classes'
import Permissions from '@/security/permissions'

export class PortalPermissions extends PermissionsClass {
  constructor(currentUser) {
    super(currentUser, {
      read: Permissions.portalUserRead,
      create: Permissions.portalUserCreate,
      edit: Permissions.portalUserEdit,
      delete: Permissions.portalUserDestroy,
      changeStatus: Permissions.portalUserChangeStatus
    })
  }
}
